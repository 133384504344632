
.main-content-wrapper {
  padding: var(--margin-xl) var(--margin-2xl);
}

.admin-view-toolbar {
  padding-top: var(--margin-l);
  padding-left: var(--margin-l);
  padding-right: var(--margin-l);
}

.title-back-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5em;
  width: 1.5em;
}
