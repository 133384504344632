
.boxy-layout {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
}

.shader,
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.background-image {
  // filter: blur(2px);
  //  -webkit-filter: blur(2px);
  // background-position: center;
  // background-size: cover;
  // background-repeat: no-repeat;
}

.shader {
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 2;
}

.boxy__outer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 100%;
  overflow: hidden;
  z-index: 4;
}

.boxy__inner {
  position: relative;
  z-index: 4;
  max-height: 90vh;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.36);
}

.boxy__card {
  @media (max-width: 640px) {
    padding: 20px 15px;
  }
}
