
.content-area {
  position: relative;
  min-height: 100vh;
  min-height: 100lvh;
  background: var(--c-bg);
  width: auto;
  max-width: 70%;
  min-width: 30%;
  padding: var(--margin-s) var(--margin-max);

  @media (max-width: 767px) {
    padding: var(--margin-m) var(--margin-xl);
    height: 100%;
    min-height: 100vh;
    min-height: 100lvh;
    width: 100%;
    max-width: 90vw;
    max-width: 90lvw;
    margin: 0;
    overflow: visible;
  }

  @media (max-width: 500px) {
    padding: var(--margin-m);
  }

  &:before {
    content: " ";
    top: 0;
    inset-inline-start: auto;
    inset-inline-end: -5%;
    position: absolute;
    z-index: var(--z-5);
    width: 0;
    height: 100%;
    transform: rotate(4deg);
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.6);

    @media (max-width: 767px) {
      display: none;
    }
  }

  &:after {
    content: " ";
    top: 0;
    inset-inline-start: calc(100% - 1px);
    position: absolute;
    z-index: var(--z-4);
    width: 10%;
    height: 100%;
    background: var(--c-bg);
    clip-path: polygon(100% 0, 0 0, 0 103%);

    @media (max-width: 767px) {
      inset-inline-start: auto;
      inset-inline-end: 0;
      width: 0;
      clip-path: none;
      box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.6);
    }
  }
}
