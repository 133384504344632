
.external-wrapper {
  --pd-menu-width: 200px;

  padding-inline: var(--container-gutter);
  @media (max-width: 1024px) {
    padding-inline-start: 0;
  }
}

.menu-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  inset-inline-start: 100%;
}

.side-menu-container {
  position: relative;
  width: var(--pd-menu-width);
  max-width: var(--pd-menu-width);
  z-index: var(--z-2);

  @media (max-width: 1024px) {
    width: 0;
    max-width: 0;

    .nav-inner-wrapper {
      position: absolute;
      width: var(--pd-menu-width);
      max-width: var(--pd-menu-width);
      top: 0;
      height: 100%;
      max-height: 100%;
      inset-inline-start: calc(-1 * var(--pd-menu-width) + 25px);
      min-height: 100%;
      transition: inset 175ms ease-in;
      border-radius: 0 !important;
      border-inline-end: 2px solid var(--c-lead);
      box-shadow: var(--box-shadow-5);

      .side-nav {
        opacity: 0;
        transition: opacity 200ms ease-in 50ms;
      }
    }

    &.active {
      .nav-inner-wrapper {
        margin-inline-start: 0;
        opacity: 1;
        pointer-events: auto;
        inset-inline-start: 0;
        .side-nav {
          opacity: 1;
        }
      }
    }
  }
  /*
@media (max-width: 1024px) {
  width: 250px;
  max-width: 250px;
  margin-inline-start: calc(-250px + 10px);
  transition: 200ms ease;

}

&.active {
  margin-inline-start: 0;
  opacity: 1;
}

 */
}
